import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Services, Validations, Constants } from '../utils'
import Context from '../utils/Context'
import getError from '../utils/getError'

const { ApiAuthStatus: API_AUTH_STATUS } = Constants

class LoginWithPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.validatePassword = this.validatePassword.bind(this)
    this.action = this.action.bind(this)
  }

  validatePassword({
    email,
    password,
    userAccounts,
    recaptcha,
    fingerprint,
    handlers: {
      setGlobalLoading,
      setMfaPhone,
      // eslint-disable-next-line camelcase
      DEPRECATED_withSession,
      handleUserAccountsChange,
      handleCurrentPasswordChange,
      handlePasswordChange,
    },
  }) {
    const {
      useNewSession,
      onSuccess,
      onRequiresSmsMfa,
      onRequiresAppMfa,
      onRequiresMfaRegistration,
      onRequiresPasswordUpdate,
      onFailure,
      saveUserAccount,
      parentAppId,
    } = this.props

    this.setState({ loading: true })
    setGlobalLoading(true)

    return (
      useNewSession
        ? DEPRECATED_withSession(() =>
            Services.validatePassword({
              login: email,
              password,
              recaptcha,
              recaptchaToken: null,
              fingerprint,
              parentAppId,
            })
          )
        : Services.validatePassword({
            login: email,
            password,
            recaptcha,
            fingerprint,
            recaptchaToken: null,
            parentAppId,
          })
    )
      .then(({ authStatus, phoneNumber, lastAttemptAvailable }) => {
        this.setState({ loading: false }, () => {
          if (authStatus === API_AUTH_STATUS.Success) {
            if (saveUserAccount && !userAccounts.includes(email)) {
              handleUserAccountsChange([...userAccounts, email])
            }

            return setGlobalLoading(false, onSuccess)
          }

          if (authStatus === API_AUTH_STATUS.RequiresMFAAuthenticator) {
            return setGlobalLoading(false, onRequiresAppMfa)
          }

          if (authStatus === API_AUTH_STATUS.RequiresPhoneRegistration) {
            return setGlobalLoading(false, onRequiresMfaRegistration)
          }

          if (authStatus === API_AUTH_STATUS.RequiresMFA) {
            return setMfaPhone(phoneNumber, onRequiresSmsMfa)
          }

          if (authStatus === API_AUTH_STATUS.ExpiredPassword) {
            return setGlobalLoading(false, () => {
              handleCurrentPasswordChange(password)
              handlePasswordChange('')
              onRequiresPasswordUpdate()
            })
          }

          if (authStatus === API_AUTH_STATUS.WrongCredentials) {
            return setGlobalLoading(false, () => {
              onFailure(
                getError({ authStatus, details: { lastAttemptAvailable } })
              )
            })
          }

          return setGlobalLoading(false, () =>
            onFailure(getError({ authStatus }))
          )
        })
      })
      .catch(({ response }) => {
        const error = response && response.data

        this.setState({ loading: false }, () => {
          setGlobalLoading(false, () => onFailure(getError(error)))
        })
      })
  }

  action() {
    const { email, password, userAccounts, recaptcha, fingerprint } =
      this.props.state

    this.props.handlers.handleRecaptchaChange('')

    return this.validatePassword({
      email,
      password,
      userAccounts,
      recaptcha,
      fingerprint,
      handlers: this.props.handlers,
    })
  }

  render() {
    const { email, password } = this.props.state

    return this.props.children({
      state: { email, password },
      loading: this.state.loading,
      action: () => this.action(),
      validation: Validations,
    })
  }
}

const Wrapper = props => (
  <Context.Consumer>
    {({ state, handlers, parentAppId }) => (
      <LoginWithPassword
        {...props}
        state={state}
        handlers={handlers}
        parentAppId={parentAppId}
      />
    )}
  </Context.Consumer>
)

LoginWithPassword.propTypes = {
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onRequiresSmsMfa: PropTypes.func,
  onRequiresAppMfa: PropTypes.func,
  onRequiresPasswordUpdate: PropTypes.func,
  onRequiresMfaRegistration: PropTypes.func,
  useNewSession: PropTypes.bool,
  saveUserAccount: PropTypes.bool,
  state: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
  parentAppId: PropTypes.string,
}

LoginWithPassword.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
  onRequiresSmsMfa: () => {},
  onRequiresAppMfa: () => {},
  onRequiresPasswordUpdate: () => {},
  onRequiresMfaRegistration: () => {},
  useNewSession: false,
}

export default Wrapper
